import { useEffect, useState } from 'react';
import IconButton from 'components/common/IconButton';
import { Row, Col, Modal, Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import Create from './Create';
import CallApi from 'components/common/custom-components/CallApi';
import PageInfo from 'components/common/custom-components/PageInfo';
import React from 'react';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [apiCallInProcess, setApiCallInProcess] = useState(true);

  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      headerProps: {
        className: 'text-center'
      },
      Cell: ({ cell }) => {
        return (
          <>
            <IconButton
              icon="edit"
              size="sm"
              className="me-2"
              onClick={() => updateAccount(cell.row.values.id)}
            ></IconButton>
            <IconButton
              icon="trash-alt"
              size="sm"
              onClick={() => deleteAccount(cell.row.values.id)}
              variant="danger"
            ></IconButton>
          </>
        );
      }
    },
    {
      accessor: 'client.name',
      Header: 'Client',
      Cell: ({ cell }) => {
        return (
          <NameWithIcon
            name={cell.value}
            icon={cell.row.original?.client?.favicon}
          />
        );
      }
    },
    {
      accessor: 'account_id',
      Header: 'Account ID'
    },
    {
      accessor: 'account_name',
      Header: 'Name'
    },
    {
      accessor: 'network.name',
      Header: 'Network',
      Cell: ({ cell }) => (
        <>
          <FontAwesomeIcon
            icon={[
              cell.row.original?.network?.icon_prefix,
              cell.row.original?.network?.icon_name
            ]}
          />{' '}
          {cell.value}
        </>
      )
    },
    // {
    //   accessor: 'region.name',
    //   Header: 'Region'
    // },
    {
      accessor: 'primary_user.name',
      Header: 'Primary User',
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.value}
          icon={cell.row.original?.primary_user?.avatar_url}
        />
      )
    },
    // {
    //   accessor: 'secondary_user.name',
    //   Header: 'Secondary User',
    //   Cell: ({ cell }) => (
    //     <NameWithIcon
    //       name={cell.value}
    //       icon={cell.row.original?.secondary_user?.avatar_url}
    //     />
    //   )
    // },
    {
      accessor: 'status',
      Header: 'Status'
    },
    {
      accessor: 'currency',
      Header: 'Currency'
    },
    // {
    //   accessor: 'account_note',
    //   Header: 'Account Note'
    // },
    {
      accessor: 'website_url',
      Header: 'Website Url'
    },
    {
      accessor: 'quick_link_to_account',
      Header: 'Quick link to account'
    }
  ];

  function deleteAccount(id) {
    if (confirm("Attention! Are you sure you'd like to delete?")) {
      CallApi.delete(`/accounts/${id}`).then(json => {
        if (json.status == 200) loadListing();
      });
    }
  }

  function updateAccount(id) {
    setAccountId(id);
    setShow(true);
  }

  function loadListing() {
    CallApi.get(`/accounts`, apiCallInProcess).then(data => {
      setApiCallInProcess(false);
      if (data.status == 200) {
        setAccounts(data.data);
      }
    });
  }

  function loadMetricListing() {
    CallApi.get(`/metrics`, false).then(data => {
      if (data.status == 200) setMetrics(data.data);
    });
  }

  function loadUserListing() {
    CallApi.get(`/users?filter[role.name]=admin,user`, false).then(data => {
      if (data.status == 200) setUsers(data.data);
    });
  }

  useEffect(() => {
    loadListing();
    loadMetricListing();
    loadUserListing();
  }, []);

  const handleShow = () => setShow(true);
  const handleHide = () => {
    setAccountId('');
    setShow(false);
  };

  return (
    <>
      <PageInfo slug="accounts" />
      <Card>
        <Card.Body>
          <AdvanceTableWrapper
            columns={columns}
            data={accounts}
            sortable
            pagination
          >
            <Row className="align-items-end g-2 row mb-3">
              <Col xs="auto">
                <AdvanceTableSearchBox table />
              </Col>
              <Col />
              <Col xs="auto">
                <IconButton
                  icon="plus"
                  size="sm"
                  className="float-end"
                  onClick={handleShow}
                >
                  Add New
                </IconButton>
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              apiCallInProcess={apiCallInProcess}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={accounts.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>

      <Create
        loadListing={loadListing}
        setShow={setShow}
        accountId={accountId}
        setAccountId={setAccountId}
        metrics={metrics}
        users={users}
        show={show}
        onHide={handleHide}
      />
    </>
  );
};

export default Accounts;
