import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import AdvanceTableWrapper, {
  SelectBoxFilterMenu
} from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import { getYYYYMMDDFormatDate } from 'helpers/utils';
import DatePicker from 'react-datepicker';
import ExportXLSX from './ExportXLSX';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import { useMutation, useQuery } from 'react-query';
import ExportGoogleAdsXLSX from './ExportGoogleAdsXLSX';

const BudgetOverviewListing = ({
  setshowImportModal,
  setshowAddModal,
  editBudgetOverview,
  refreshListing
}) => {
  const today = new Date();
  const [date, setDate] = useState(today);
  const [filters, setFilters] = useState({ network_id: '', manager_id: '' });

  const NetworksQuery = useQuery(
    ['networks'],
    () => CallApi.get('/networks', false),
    { placeholderData: { data: [] } }
  );

  const ManagersQuery = useQuery(
    ['managers'],
    () => CallApi.get('/users?filter[role.name]=admin,user', false),
    { placeholderData: { data: [] } }
  );

  const BudgetOverviewQuery = useQuery(
    [
      'budget-overviews',
      filters.network_id,
      filters.manager_id,
      refreshListing,
      date
    ],
    () =>
      CallApi.get(
        `/budget-overviews?date=${getYYYYMMDDFormatDate(date)}&network=${
          filters.network_id
        }&manager=${filters.manager_id}`,
        true
      ),
    { placeholderData: { data: [] } }
  );

  // NOTE: If you are introducing a new column in listing, you must define its index in ignoreable columns for exporting. otherwise newly introduced column gonna break the structure of the sheet.
  const columns = [
    {
      accessor: 'id',
      Header: 'Actions',
      Cell: ({ cell }) => {
        return (
          <IconButton
            icon="edit"
            size="sm"
            onClick={() => editBudgetOverview(cell.row.values.id)}
          />
        );
      }
    },
    {
      accessor: 'client.name',
      Header: 'Client',
      Filter: SelectBoxFilterMenu,
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.value}
          icon={cell.row.original.client?.favicon}
        />
      )
    },
    {
      accessor: 'account_id',
      Header: 'Account ID',
      Filter: SelectBoxFilterMenu,
      Cell: ({ cell }) => {
        return (
          <>
            <FontAwesomeIcon
              icon={[
                cell.row.original.network.icon_prefix,
                cell.row.original.network.icon_name
              ]}
            />{' '}
            {cell.value}
          </>
        );
      }
    },
    {
      accessor: 'account_name',
      Filter: SelectBoxFilterMenu,
      Header: 'Account Name'
    },
    {
      accessor: 'account_primary_analyst.first_name',
      Header: 'Manager',
      Filter: SelectBoxFilterMenu,
      Cell: ({ cell }) => (
        <NameWithIcon
          icon={cell.row.original.account_primary_analyst.avatar_url}
          name={cell.value}
        />
      )
    },
    {
      accessor: 'campaign_name',
      Header: 'Campaign Name'
    },
    {
      accessor: 'temporary',
      Header: 'Temporary',
      cellProps: {
        className: 'text-center'
      },
      Filter: SelectBoxFilterMenu,
      Cell: ({ cell }) => (
        <div className={cell.value ? 'text-success' : 'text-danger'}>
          <FontAwesomeIcon
            icon={['fas', cell.value ? 'check' : 'times']}
            size="lg"
          />
        </div>
      )
    },
    {
      accessor: 'short_code',
      Header: 'Short Code'
    },
    {
      accessor: 'start_date',
      Header: 'Start Date'
    },
    {
      accessor: 'end_date',
      Header: 'End Date'
    },
    {
      accessor: 'invoice_media',
      Header: 'Inv. M',
      sortType: 'basic',
      Cell: ({ cell }) => (
        <div className="text-end">{cell.row.values.invoice_media}</div>
      )
    },
    {
      accessor: 'invoice_fee',
      Header: 'Fee',
      sortType: 'basic',
      Cell: ({ cell }) => (
        <div className="text-end">{cell.row.values.invoice_fee}</div>
      )
    },
    {
      accessor: 'spend',
      Header: 'Spend',
      sortType: 'basic',
      Cell: ({ cell }) => (
        <div className="text-end">{cell.row.values.spend}</div>
      )
    },
    {
      accessor: 'goal_primary',
      Header: 'Goal Primary'
    },
    {
      accessor: 'goal_secondary',
      Header: 'Goal Secondary'
    },
    {
      accessor: 'goal_conversions',
      Header: 'Goal Conversion',
      sortType: 'basic',
      Cell: ({ cell }) => (
        <div className="text-end">{cell.row.values.goal_conversions}</div>
      )
    },
    {
      accessor: 'goal_conversion_values',
      Header: 'Goal Conversion Values',
      sortType: 'basic',
      Cell: ({ cell }) => (
        <div className="text-end">{cell.row.values.goal_conversion_values}</div>
      )
    },
    {
      accessor: 'goal_clicks',
      Header: 'Goal Clicks',
      sortType: 'basic',
      Cell: ({ cell }) => (
        <div className="text-end">{cell.row.values.goal_clicks}</div>
      )
    },
    {
      accessor: 'goal_impressions',
      Header: 'Goal Impressions',
      sortType: 'basic',
      Cell: ({ cell }) => (
        <div className="text-end">{cell.row.values.goal_impressions}</div>
      )
    },
    {
      accessor: 'note',
      Header: 'Note'
    },
    {
      accessor: 'invoice_id',
      Header: 'Invoice Id',
      Cell: ({ cell }) => (
        <div className="text-end">{cell.row.values.invoice_id}</div>
      )
    }
  ];

  const ExportBudgetOverviewsMutation = useMutation(formData =>
    CallApi.get(`/budget-overviews?export=google-ads-accounts`)
  );

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={BudgetOverviewQuery?.data?.data}
      sortable
      pagination
    >
      <Row className="align-items-end g-2 row mb-3">
        <Col className="col-auto">
          <AdvanceTableSearchBox table />
        </Col>
        <Col />
        <Col xs="auto">
          <Form.Select
            size="sm"
            onChange={e =>
              setFilters({ ...filters, network_id: e.target.value })
            }
          >
            <option value="">All Networks</option>
            {NetworksQuery?.data?.data?.map(network => (
              <option key={network.id} value={network.id}>
                {network.name}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs="auto">
          <Form.Select
            size="sm"
            onChange={e =>
              setFilters({ ...filters, manager_id: e.target.value })
            }
          >
            <option value="">All Managers</option>
            {ManagersQuery?.data?.data?.map(manager => (
              <option key={manager.id} value={manager.id}>
                {manager.name}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col className="col-auto">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={date}
            onChange={date => setDate(date)}
            className="form-control pb-0 pt-1"
            placeholderText="Select Date"
            calendarStartDay={1}
          />
        </Col>
        <Col className="col-auto text-end">
          {/* <IconButton
            className="me-2"
            size="sm"
            icon={
              ExportBudgetOverviewsMutation.isLoading
                ? 'spinner'
                : ['far', 'file-excel']
            }
            disabled={ExportBudgetOverviewsMutation.isLoading}
            spin={ExportBudgetOverviewsMutation.isLoading}
            onClick={() => {
              ExportBudgetOverviewsMutation.mutate();
            }}
          >
            Export Google Ads
          </IconButton> */}
          <ExportGoogleAdsXLSX table />
          <IconButton
            icon="plus"
            size="sm"
            onClick={() => setshowAddModal(true)}
          >
            Add New
          </IconButton>
          <IconButton
            icon="file-import"
            size="sm"
            className="ms-2"
            onClick={() => setshowImportModal(true)}
          >
            Import
          </IconButton>
          <ExportXLSX
            table
            sheetHeaderRow={[
              'Account ID',
              'Campaign Name',
              'Temporary',
              'Short Code',
              'Start Date',
              'End Date',
              'Invoice Media',
              'Invoice Fee',
              'Spend',
              'Goal Primary',
              'Goal Secondary',
              'Goal Conversions',
              'Goal Conversion Values',
              'Goal Clicks',
              'Goal Impressions',
              'Note',
              'Invoice ID'
            ]}
            filename={`${getYYYYMMDDFormatDate(new Date())} - Budget Overview`}
            ignoreableColumnIndexes={[0, 1, 3, 4]}
          />
        </Col>
      </Row>
      <AdvanceTable
        table
        headerClassName="bg-200 text-900 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          bordered: true,
          striped: true,
          className: 'fs--1 mb-0 overflow-hidden',
          id: 'budgetOverviews'
        }}
        apiCallInProcess={BudgetOverviewQuery.isFetching}
      />
      <div className="mt-3">
        <AdvanceTableFooter
          rowCount={BudgetOverviewQuery?.data?.data?.length}
          table
          rowInfo
          navButtons
          rowsPerPageSelection
        />
      </div>
    </AdvanceTableWrapper>
  );
};

export default BudgetOverviewListing;
