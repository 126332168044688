import IconButton from 'components/common/IconButton';
import React from 'react';
import generateExcel from 'zipcelx';

const ExportGoogleAdsXLSX = ({ page, className = 'me-2' }) => {
  const getExcel = () => {
    console.log('Generating google ads excel file');
    const config = {
      filename: 'Google Ads Accounts',
      sheet: {
        data: []
      }
    };

    const dataSet = config.sheet.data;

    // Pre-filled cell we want so we can directly import in google ads
    dataSet.push([
      {
        value: 'Parameters:BillingActionType=NewAccountBudgets;',
        type: 'string'
      }
    ]);

    // table header
    dataSet.push([
      { value: 'Customer ID', type: 'string' },
      { value: 'Currency', type: 'string' },
      { value: 'Budget Amount', type: 'string' },
      { value: 'Start Date', type: 'string' },
      { value: 'End Date', type: 'string' },
      { value: 'Budget Name', type: 'string' },
      { value: 'Purchase Order', type: 'string' },
      { value: 'Notes', type: 'string' }
    ]);

    // table data
    page
      .filter(row => row.original?.network?.slug === 'google-ads')
      .forEach(row => {
        const budgetOverview = row.original;

        dataSet.push([
          { value: budgetOverview?.account?.account_id, type: 'string' },
          { value: budgetOverview?.account?.currency, type: 'string' },
          { value: budgetOverview?.spend, type: 'number' },
          { value: budgetOverview?.start_date, type: 'string' },
          { value: budgetOverview?.end_date, type: 'string' },
          { value: budgetOverview?.campaign_name, type: 'string' },
          { value: '', type: 'string' },
          { value: '', type: 'string' }
        ]);
      });

    console.log('Generated google ads excel file');
    return generateExcel(config);
  };

  return (
    <IconButton
      className={className}
      size="sm"
      icon={['far', 'file-excel']}
      onClick={getExcel}
      type="button"
    >
      Export Google Ads
    </IconButton>
  );
};

export default ExportGoogleAdsXLSX;
