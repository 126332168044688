/* eslint-disablereact/prop-types */
import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Form, Button } from 'react-bootstrap';
import DayByDaySplit from './DayByDaySplit';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallApi from 'components/common/custom-components/CallApi';
import IconButton from 'components/common/IconButton';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import { useRef } from 'react';
import { HiSaveAs } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

const UpsertBudgetOverview = ({
  showModal,
  setShowModal,
  currentBudgetOverviewId,
  setCurrentBudgetOverviewId,
  loadlisting
}) => {
  const emptyBudgetOverview = {
    goal_conversions: 0,
    goal_clicks: 0,
    goal_conversion_values: 0,
    goal_impressions: 0,
    temporary: false,
    start_date: '',
    end_date: '',
    invoice_media: 0,
    invoice_fee: 0,
    spend: 0,
    campaign_name: '',
    short_code: '',
    goal_secondary: '',
    goal_primary: '',
    invoice_id: '',
    note: ''
  };

  const [budgetOverview, setBudgetOverview] = useState(emptyBudgetOverview);
  const [budgetDaily, setBudgetDaily] = useState([]);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [fetchingExistingBudgetOverview, setFetchingExistingBudgetOverview] =
    useState(false);

  const SubscribersQuery = useQuery('subscribers', () =>
    CallApi.get(`/users?filter[role.name]=admin,user`, false)
  );

  useEffect(() => {
    if (SubscribersQuery.isFetched) {
      setSubscribers(
        SubscribersQuery.data?.data?.map(user => {
          return {
            value: user.id,
            label: user.name
          };
        })
      );
    }
    fetchBudget(currentBudgetOverviewId);
  }, [currentBudgetOverviewId, SubscribersQuery.isFetching]);

  const AccountsQuery = useQuery(
    'budget_overview_accounts',
    () => CallApi.get(`/accounts?filter[status]=active`, false),
    {
      placeholderData: { data: [] },
      enabled: !!showModal
    }
  );

  const fetchBudget = budgetId => {
    if (budgetId) {
      setFetchingExistingBudgetOverview(true);
      CallApi.get(`/budget-overviews/${budgetId}`, false).then(json => {
        if (json.status == 200) {
          setBudgetOverview(json.data);
          setBudgetDaily(json.data.budgets);
          setCurrentAccount(
            AccountsQuery.data.data.find(
              account => account.id == json.data.account_id
            )
          );
          setSubscriptions(
            json.data.subscriptions.map(subscription => {
              return {
                value: subscription.user_id,
                label: subscription.user.name
              };
            })
          );
          setFetchingExistingBudgetOverview(false);
        }
      });
    }
  };

  const handleSubscribersChange = value => {
    if (value) setSubscriptions(value.map(subscription => subscription));
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? +target.checked : target.value;
    const name = target.name;
    console.log(name, value, target, target.type);
    setBudgetOverview({ ...budgetOverview, [name]: value });
  };

  const saveBudgetOverview = event => {
    event.preventDefault();
    let postData = new FormData(event.target);
    let upsertUrl = '/budget-overviews';

    // check if splitted-spend is equal to total spend.
    if (
      budgetOverview.spend !=
      budgetDaily.reduce((totalSpend, currentDailyBudget) => {
        return totalSpend + currentDailyBudget.spend;
      }, 0)
    ) {
      toast.error('Difference in Splitted-Spend & Spend is found!');
      return;
    }

    // check if daily budgets have any negative values
    if (budgetDaily.some(item => item.spend < 0)) {
      toast.error(
        'Adjust daily budgets so it does not have any negative valeus.'
      );

      if (DayByDaySplitRef.current.evenSplitSaving)
        DayByDaySplitRef.current.turnOffEvenSplitSaving();

      return;
    }

    postData.append('budgets', JSON.stringify(budgetDaily));

    if (currentBudgetOverviewId) {
      CallApi.put(upsertUrl + `/${currentBudgetOverviewId}`, postData).then(
        json => {
          if (json.status == 200) {
            loadlisting();
            clearAndCloseModal();
          }
        }
      );
    } else {
      CallApi.post(upsertUrl, postData).then(json => {
        if (json.status == 200) {
          loadlisting();
          clearAndCloseModal();
        }
      });
    }
  };

  const getAccountSelectboxLabel = data => {
    return (
      <span className="text-dark">
        {data?.client?.name} -{' '}
        <FontAwesomeIcon
          icon={[data?.network?.icon_prefix, data?.network?.icon_name]}
          title={data?.status}
        />
        &nbsp;{data?.network?.name} - {data?.account_id}{' '}
        {data?.account_name ? `- ${data?.account_name}` : ''}
      </span>
    );
  };

  const clearAndCloseModal = () => {
    setBudgetOverview(emptyBudgetOverview);
    setBudgetDaily([]);
    setCurrentBudgetOverviewId(null);
    setCurrentAccount(null);
    setSubscriptions(null);
    setShowDeleteModal(false);
    setShowModal(false);
  };

  const deleteBudgetOverview = () => {
    CallApi.delete('/budget-overviews/' + currentBudgetOverviewId).then(
      json => {
        if (json.status == 200) clearAndCloseModal();
      }
    );
  };

  const FormSubmitButton = useRef();
  const DayByDaySplitRef = useRef();

  const submitForm = e => FormSubmitButton.current.click();

  let fetchingEverything =
    fetchingExistingBudgetOverview ||
    AccountsQuery.isFetching ||
    SubscribersQuery.isFetching;

  return (
    <>
      <Modal
        fullscreen={true}
        show={showModal}
        onHide={clearAndCloseModal}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {currentBudgetOverviewId
              ? 'Editing Budget ID: ' + currentBudgetOverviewId
              : 'Add a new Budget'}
            {fetchingEverything && (
              <FontAwesomeIcon icon="spinner" className="ms-3" pulse />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form
                id="add-budget-overview"
                encType="multipart/form-data"
                onSubmit={saveBudgetOverview}
              >
                <input type="submit" ref={FormSubmitButton} hidden />
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Account{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_account" />
                  </Form.Label>
                  <Col sm="8">
                    <Select
                      name="account_id"
                      options={AccountsQuery.data?.data}
                      getOptionLabel={account =>
                        `${account.client.name} - ${account.network.name} - ${account.account_id} - ${account.account_name}`
                      }
                      getOptionValue={account => account.id}
                      formatOptionLabel={getAccountSelectboxLabel}
                      onChange={account => {
                        setBudgetOverview({
                          ...budgetOverview,
                          account_id: account.id
                        });
                        setCurrentAccount(account);
                      }}
                      classNamePrefix="react-select"
                      value={currentAccount}
                      required
                      isDisabled={fetchingEverything}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Subscriber(s){' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_subscribers" />
                  </Form.Label>
                  <Col>
                    <Select
                      closeMenuOnSelect={false}
                      options={subscribers}
                      placeholder="Select..."
                      isMulti
                      classNamePrefix="react-select"
                      name="subscribers[]"
                      value={subscriptions}
                      onChange={handleSubscribersChange}
                      isDisabled={fetchingEverything}
                    />
                    {/*<Form.Control.Feedback type="invalid">Please choose a secondary metric.</Form.Control.Feedback>*/}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Start Date
                  </Form.Label>
                  <Col>
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.start_date}
                      name="start_date"
                      disabled={fetchingEverything}
                      type="date"
                      required
                      max="9999-12-31"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    End Date
                  </Form.Label>
                  <Col>
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.end_date}
                      name="end_date"
                      disabled={fetchingEverything}
                      type="date"
                      max="9999-12-31"
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Invoice Media{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_invoice_media" />
                  </Form.Label>
                  <Col>
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.invoice_media || ''}
                      name="invoice_media"
                      disabled={fetchingEverything}
                      type="number"
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Invoice Fee{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_invoice_fee" />
                  </Form.Label>
                  <Col>
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.invoice_fee || ''}
                      name="invoice_fee"
                      type="number"
                      disabled={fetchingEverything}
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Spend{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_spend" />
                  </Form.Label>
                  <Col>
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.spend || ''}
                      name="spend"
                      disabled={fetchingEverything}
                      type="number"
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Temporary{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_temporary" />
                  </Form.Label>
                  <Col>
                    <Form.Check
                      type="switch"
                      name="temporary"
                      onChange={handleInputChange}
                      disabled={fetchingEverything}
                      checked={budgetOverview.temporary}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Campaign Name{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_campaign_name" />
                  </Form.Label>
                  <Col>
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.campaign_name}
                      name="campaign_name"
                      disabled={fetchingEverything}
                      type="text"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Short code{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_short_code" />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.short_code}
                      name="short_code"
                      disabled={fetchingEverything}
                      type="text"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Primary Goal{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_primary_goal" />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Select
                      name="goal_primary"
                      value={budgetOverview.goal_primary}
                      onChange={e =>
                        setBudgetOverview({
                          ...budgetOverview,
                          goal_primary: e.target.value
                        })
                      }
                      disabled={fetchingEverything}
                      required
                    >
                      <option value="">Select a value</option>
                      <option value="conversions">Conversions</option>
                      <option value="conversion_values">
                        Conversion Values
                      </option>
                      <option value="impressions">Impressions</option>
                      <option value="clicks">Clicks</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Secondary Goal{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_secondary_goal" />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Select
                      name="goal_secondary"
                      value={budgetOverview.goal_secondary}
                      onChange={e =>
                        setBudgetOverview({
                          ...budgetOverview,
                          goal_secondary: e.target.value
                        })
                      }
                      disabled={fetchingEverything}
                      required
                    >
                      <option value="">Select a value</option>
                      <option value="conversions">Conversions</option>
                      <option value="conversion_values">
                        Conversion Values
                      </option>
                      <option value="impressions">Impressions</option>
                      <option value="clicks">Clicks</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Goal Conversions{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_goal_conversions" />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.goal_conversions || ''}
                      name="goal_conversions"
                      disabled={fetchingEverything}
                      type="number"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Goal Conversion Values{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_goal_conversion_values" />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.goal_conversion_values || ''}
                      name="goal_conversion_values"
                      disabled={fetchingEverything}
                      type="number"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Goal Impressions{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_goal_impressions" />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.goal_impressions || ''}
                      name="goal_impressions"
                      disabled={fetchingEverything}
                      type="number"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Goal Clicks{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_goal_clicks" />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.goal_clicks || ''}
                      name="goal_clicks"
                      disabled={fetchingEverything}
                      type="number"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Invoice ID{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_invoice_id" />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      onChange={handleInputChange}
                      value={budgetOverview.invoice_id}
                      name="invoice_id"
                      disabled={fetchingEverything}
                      type="number"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Note{' '}
                    <LocalStorageTooltip identifier="upsert_budget_overview_note" />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as={'textarea'}
                      onChange={handleInputChange}
                      value={budgetOverview.note}
                      name="note"
                      disabled={fetchingEverything}
                      type="text"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </Col>
            <Col md={6}>
              <DayByDaySplit
                ref={DayByDaySplitRef}
                submitForm={submitForm}
                budgetOverview={budgetOverview}
                budgetDaily={budgetDaily}
                setBudgetDaily={setBudgetDaily}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {currentBudgetOverviewId && (
            <IconButton
              className="me-auto"
              icon="trash-alt"
              size="sm"
              onClick={() => {
                setShowModal(false);
                setShowDeleteModal(true);
              }}
              variant="danger"
            >
              Remove
            </IconButton>
          )}
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={clearAndCloseModal}
            variant="falcon-light"
          >
            Close
          </IconButton>
          <Button
            size="sm"
            onClick={() => DayByDaySplitRef.current.splitAndSave()}
          >
            <HiSaveAs className="fs-1" /> Even Split & Save
          </Button>
          <IconButton
            icon="save"
            size="sm"
            variant="secondary"
            form="add-budget-overview"
            type="submit"
          >
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} size="lg">
        <Modal.Header>
          <Modal.Title>Delete Budget Overview?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FontAwesomeIcon icon="exclamation-circle" color="red" /> Are you sure
          you want to delete {budgetOverview.campaign_name}?
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={clearAndCloseModal}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            icon="trash-alt"
            size="sm"
            variant="danger"
            onClick={deleteBudgetOverview}
          >
            Remove
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpsertBudgetOverview;
